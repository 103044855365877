import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout pageTitle="Not Found">
    <p>Page not found</p>
  </Layout>
)

export default NotFoundPage
